import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Section
} from '../../components';
import { getColumnSpanSize, H1, H2, media, P, Wysiwyg } from '../../global';
import BaseLayout from '../../layouts/Base';

// prettier-ignore
const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 700px;
`;

const Title = styled(H1)`
  text-transform: lowercase;
`;

const Content = styled(Wysiwyg)`
  margin-top: 32px;
`;

const Accessibility = ({ title, content, ...layoutProps }) => {
  return (
    <BaseLayout footerDivider {...layoutProps}>
      <Section>
        <Wrapper>
          <Title>{title}</Title>
          <Content dangerouslySetInnerHTML={{ __html: content }} />
        </Wrapper>
      </Section>
    </BaseLayout>
  );
};

Accessibility.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Accessibility;
